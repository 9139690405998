import { XMarkIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import GlobalText from './globalText'
import { useLocation } from "react-router-dom";
import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TfiLayoutSlider, TfiLayoutSliderAlt } from "react-icons/tfi";
import { BsLayoutSplit, BsLayoutThreeColumns, BsBlockquoteRight, BsPersonVideo2 } from "react-icons/bs";
import { TbLayoutSidebarRightCollapse, TbNumbers } from "react-icons/tb";
import { MdOutlineTitle, MdCurrencyExchange, MdCreditCard } from "react-icons/md";
import { PiDotsSixBold } from "react-icons/pi";
import axios from 'axios';
import GlobalData from './globalData';
import Cookies from 'js-cookie';
import { Toaster, toast } from 'sonner'
import ReturnComponent from './returnComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaRegNewspaper, FaWpforms, FaQuestion } from "react-icons/fa";
import { AiOutlineProduct } from "react-icons/ai";
import { FaRegFilePdf, FaList } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { IoDocumentsOutline } from "react-icons/io5";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function EditePage(props) {
    const { language, currentPathname } = props
    const [open, setOpen] = useState(false)    
    const APIURL = GlobalData('apiUrl')
    const [langP, setLangP] = useState(language)
    const pageId = currentPathname.split('/')[3]
    const [loading, setLoading] = useState(true);    
    const [page, setPage] = useState({})
    const [sendloading, setsendloading] = useState(false);  
    const Cookie = Cookies.get('token') 
    const succesText = GlobalText(38)
    const errorText = GlobalText(39)
    const [contentsTg, setcontentsTg] = useState([])
    const [contentsRu, setcontentsRu] = useState([])
    const [contentsEn, setcontentsEn] = useState([])
    const logoutText = GlobalText(89);
    const [onEdit, setOnEdit] = useState(false)

    function loadingData(){       
      axios.get(APIURL + 'page?id=' + pageId)
      .then((response) => {
          setPage(response.data)
          if(response.data.page_content_tg){
            setcontentsTg(JSON.parse(response.data.page_content_tg))
          }
          if(response.data.page_content_ru){
            setcontentsRu(JSON.parse(response.data.page_content_ru))
          }
          if(response.data.page_content_en){
            setcontentsEn(JSON.parse(response.data.page_content_en))
          }
          setLoading(false)  
      })
      .catch(function (error) {            
        console.log(error)           
      })
    }

    useEffect(() => {
      loadingData();
    }, []);

    function Save(e){
      e.preventDefault() 
      setsendloading(true)
      const data = {
        'id' : pageId,
        'page_title_tg' : document.getElementById('title_tg').value,
        'page_title_ru' : document.getElementById('title_ru').value,
        'page_title_en' : document.getElementById('title_en').value,
        'page_name' : document.getElementById('page_name').value,
        'page_content_tg' : contentsTg,
        'page_content_ru' : contentsRu,
        'page_content_en' : contentsEn,
      }   
      axios.post(APIURL + 'page/edite', data, {
          headers:{
            Authorization: `Bearer ${Cookie}`,
            'Content-Type': 'multipart/form-data'
          }
        }      
      )      
      .then((response) => {
        console.log(response.data)
        if(response.status === 200){  
          setsendloading(false) 
          toast.success(succesText)           
        }
      })
      .catch(function (error) {   
        console.log(error) 
        setsendloading(false)         
        toast.error(errorText)     
      })
      
    }

    const removecontent = (index) => {
      if (window.confirm(logoutText)) {
        const updatedDataTg = [...contentsTg];
        const updatedDataRu = [...contentsRu];
        const updatedDataEn = [...contentsEn];
        if(langP === 'tg'){
          updatedDataTg.splice(index, 1);
          setcontentsTg(updatedDataTg)
        } 
        if(langP === 'ru'){
          updatedDataRu.splice(index, 1);
          setcontentsRu(updatedDataRu)
        }    
        if(langP === 'en'){
          updatedDataEn.splice(index, 1);
          setcontentsEn(updatedDataEn)
        }        
      }
    };
    
    const handleOnDragEnd = (result) => {
      if (!result.destination) return;
      const itemsTg = [...contentsTg];
      const itemsRu = [...contentsRu];
      const itemsEn = [...contentsEn];   
      if (langP === 'tg') {        
        const [reorderedItem] = itemsTg.splice(result.source.index, 1)
        itemsTg.splice(result.destination.index, 0, reorderedItem)
        setcontentsTg(itemsTg)        
      }
      if (langP === 'ru') {
        const [reorderedItem] = itemsRu.splice(result.source.index, 1)
        itemsRu.splice(result.destination.index, 0, reorderedItem)
        setcontentsRu(itemsRu)
      } 
      if (langP === 'en') {
        const [reorderedItem] = itemsEn.splice(result.source.index, 1)
        itemsEn.splice(result.destination.index, 0, reorderedItem)
        setcontentsEn(itemsEn)
      }
    };
    
    const editeComp = (e, index) => {
      const itemsTg = [...contentsTg];
      const itemsRu = [...contentsRu];
      const itemsEn = [...contentsEn];   
      if (langP === 'tg') {        
        itemsTg[index].data = e
        setcontentsTg(itemsTg)      
      }
      if (langP === 'ru') {
        itemsRu[index].data = e
        setcontentsRu(itemsRu)
      } 
      if (langP === 'en') {
        itemsEn[index].data = e
        setcontentsEn(itemsEn)
      }
    };
    

    
    return (<div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 pt-2'>
      <Toaster richColors position="top-center"/>
      {loading ? GlobalData('loading') : <div>
        <div className='flex mx-auto justify-between py-3'>
        <div className="text-xl font-bold text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight inline-flex items-center"> {GlobalText(57)} 
            {page.page_nod === 'main' && <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(52)}</span>}
            {page.page_nod === 'news' && <span className="ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(19)}</span>}
            {page.page_nod == 'vacancies' && <span className="ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{GlobalText(148)}</span>}
        </div>
        <div className="text-md font-semi-bold text-gray-400 sm:truncate sm:text-md sm:tracking-tight inline-flex items-center"> 
        <div className='justify-self-end'>        
          <button onClick={() => setLangP('tg')}
            className={classNames(
              langP === 'tg' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'px-3 py-2 font-bold rounded-lg'
            )}>
            TJ
          </button>
          <button onClick={() => setLangP('ru')}
            className={classNames(
              langP === 'ru' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            RU
          </button>
          <button onClick={() => setLangP('en')}
            className={classNames(
              langP === 'en' ? 'text-white bg-gray-500' : 'text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-500',
              'ml-2 px-3 py-2 font-bold rounded-lg'
            )}>
            EN
          </button>
        </div> 
         </div>        
        </div>
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4"
                hidden={(langP === 'tg') ? false : true}
              >
                <div className="relative z-0 w-full mb-5">
                  <input
                      maxLength={100}
                      autoComplete='off'
                      type="text"
                      id="title_tg"
                      minLength='2'
                      required
                      placeholder=''
                      defaultValue={page['page_title_tg']}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                  <label htmlFor="title_tg" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(25)}</label>
                </div> 
              </div>
              <div className="sm:col-span-4"
                hidden={(langP === 'ru') ? false : true}
              >
                <div className="relative z-0 w-full mb-5">
                  <input
                      maxLength={100}
                      autoComplete='off'
                      type="text"
                      id="title_ru"
                      minLength='2'
                      required
                      placeholder=''
                      defaultValue={page['page_title_ru']}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                  <label htmlFor="title_ru" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(25)}</label>
                </div> 
              </div>
              <div className="sm:col-span-4"
                hidden={(langP === 'en') ? false : true}
              >
                <div className="relative z-0 w-full mb-5">
                  <input
                      maxLength={100}
                      autoComplete='off'
                      type="text"
                      id="title_en"
                      minLength='2'
                      required
                      placeholder=''
                      defaultValue={page['page_title_en']}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                  <label htmlFor="title_en" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(25)}</label>
                </div> 
              </div>
              <div className="sm:col-span-2" >
                <div className="relative z-0 w-full mb-5">
                  <input
                      maxLength={100}
                      autoComplete='off'
                      type="text"
                      id="page_name"
                      minLength='1'
                      required
                      placeholder=''
                      defaultValue={page.page_name}
                      disabled={(page.page_nod === 'main') ? true : false}
                      className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                    />
                  <label htmlFor="page_name" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">{GlobalText(24)}</label>
                  </div> 
              </div>
        </div>
          
        <div className='mt-6'>  
          <div className=''>
          {langP === 'tg' && <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contentsTg">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {contentsTg.map((component, index) => (
                        <Draggable 
                          key={index} 
                          draggableId={index.toString()} 
                          index={index}
                          isDragDisabled={onEdit}
                        >
                          {(provided) => (
                            <div 
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ReturnComponent
                                component={component}
                                index={index}
                                language={langP}
                                onDelete={() => removecontent(index)}
                                onEdit={() => setOnEdit(true)}
                                offEdit={(e, index) => {
                                  editeComp(e, index)
                                  setOnEdit(false)
                                }}
                                offEdit2={() => {
                                  setOnEdit(false)
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>           
            </div>}
            {langP === 'ru' && <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contentsRu">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {contentsRu.map((component, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ReturnComponent
                                component={component}
                                index={index}
                                language={langP}
                                onDelete={() => removecontent(index)}
                                onEdit={() => setOnEdit(true)}
                                offEdit={(e, index) => {
                                  editeComp(e, index)
                                  setOnEdit(false)
                                }}
                                offEdit2={() => {
                                  setOnEdit(false)
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>           
            </div>}
            {langP === 'en' && <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contentsEn">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {contentsEn.map((component, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ReturnComponent
                                component={component}
                                index={index}
                                language={langP}
                                onDelete={() => removecontent(index)}
                                onEdit={() => setOnEdit(true)}
                                offEdit={(e, index) => {
                                  editeComp(e, index)
                                  setOnEdit(false)
                                }}
                                offEdit2={() => {
                                  setOnEdit(false)
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>           
            </div>}
          </div>
          {(page['page_nod'] !== 'news' && page['page_nod'] !== 'vacancies') && <button onClick={(e) => (page['page_nod'] !== 'news' && page['page_nod'] !== 'vacancies') && setOpen(true)} className="w-full mt-10 hover:bg-gray-50">
              <div className="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 items-center">
              <PlusIcon className="mr-2 w-8 text-gray-300 " aria-hidden="true" />
                  <div className="text-sm text-gray-500">{GlobalText(26)}</div>
              </div>
            </button>}
            <Transition.Root show={open} as={Fragment}>
          <Dialog className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between border-b pb-5 mb-6">
                            <Dialog.Title className="text-lg font-semibold text-gray-900">{GlobalText(26)}</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3">
                            <button onClick={() => {     
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, {
                                    name: 'text',
                                    data: {
                                      title: 'New title',
                                    },
                                  }]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, {
                                    name: 'text',
                                    data: {
                                      title: 'New title',
                                    },
                                  }]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, {
                                    name: 'text',
                                    data: {
                                      title: 'New title',
                                    },
                                  }]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <MdOutlineTitle className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(65)}
                            </button>
                            <button onClick={() => {    
                                const newSlider = {
                                  name: 'slider',
                                  data: {
                                    bgColor: 'green',
                                    slides: [
                                      {
                                        title: 'New title',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                      }
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newSlider]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newSlider]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newSlider]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <TfiLayoutSlider className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(28)}
                            </button>
                            <button onClick={() => {    
                                const newSlider = {
                                  name: 'slideshow',
                                  data: {
                                    bgColor: 'green',
                                    slides: [
                                      {
                                        title: 'New title',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                      }
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newSlider]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newSlider]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newSlider]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <TfiLayoutSliderAlt className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(117)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '2block',
                                  data: {
                                    blocks: [
                                      {
                                        title: 'New title',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                      },
                                      {
                                        title: 'New title',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                      }
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutSplit  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(85)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'cards',                                  
                                  data: {
                                    title: 'Cards',
                                    titleColor: '#000000',
                                    titleBold: 'normal',
                                    titleStyle: 'normal',
                                    showAll: '1',
                                    categories: [
                                      {
                                        name: 'Visa',
                                      },
                                      {
                                        name: 'Mastercard',
                                      },
                                    ],
                                    cards: [
                                      {
                                        title: 'Card name',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                        buttonTitle2: 'Button',
                                        buttonTitleBold2: 'normal',
                                        buttonTitleStyle2: 'normal',
                                        buttonTitleColor2: '#fff',
                                        cardType: 0,
                                        details: [
                                          {
                                            title: 'Title',
                                            subtitle: 'Subtitle',
                                            titleColor: '#ffffff',
                                            titleBold: 'normal',
                                            titleStyle: 'normal',
                                            subtitleColor: '#ffffff',
                                            subtitleBold: 'normal',
                                            subtitleStyle: 'normal',
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <MdCreditCard  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(99)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'products',                                  
                                  data: {
                                    title: 'Product',
                                    titleColor: '#000000',
                                    titleBold: 'normal',
                                    titleStyle: 'normal',
                                    cards: [
                                      {
                                        title: 'Product name',
                                        subtitle: 'Subtitle',
                                        img: '',
                                        titleColor: '#ffffff',
                                        titleBold: 'normal',
                                        titleStyle: 'normal',
                                        subtitleColor: '#ffffff',
                                        subtitleBold: 'normal',
                                        subtitleStyle: 'normal',
                                        bgColor: '#000000',
                                        buttonTitle: 'Button',
                                        buttonBg: '#ffffff',
                                        buttonTitleBold: 'normal',
                                        buttonTitleStyle: 'normal',
                                        buttonTitleColor: '#000000',
                                        buttonTitle2: 'Button',
                                        buttonTitleBold2: 'normal',
                                        buttonTitleStyle2: 'normal',
                                        buttonTitleColor2: '#fff',
                                        details: [
                                          {
                                            title: 'Title',
                                            subtitle: 'Subtitle',
                                            titleColor: '#ffffff',
                                            titleBold: 'normal',
                                            titleStyle: 'normal',
                                            subtitleColor: '#ffffff',
                                            subtitleBold: 'normal',
                                            subtitleStyle: 'normal',
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <AiOutlineProduct  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(115)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '4x1',                                  
                                  data: {
                                    title: '4x1',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <PiDotsSixBold  className='h-10 mb-2 w-full items-center'/>
                              4x1
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '6x1',                                  
                                  data: {
                                    title: '6х1',
                                    titleColor: '#000000',
                                    subtitleBold: 'normal',
                                    subtitleStyle: 'normal',
                                    itemColor: '#000000',
                                    itemBg: '#F7F8F9',
                                    items: [
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                      {
                                        title: 'Title',
                                        url: '',
                                        img: '',
                                      },
                                    ]
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <PiDotsSixBold  className='h-10 mb-2 w-full items-center'/>
                              6x1
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'news',                                  
                                  data: {
                                    title: 'news',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaRegNewspaper  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(19)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'rate',                                  
                                  data: {
                                    title: 'rate',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <MdCurrencyExchange  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(88)}
                            </button>                            
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block',                                  
                                  data: {
                                    title: '3block',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block2',                                  
                                  data: {
                                    title: '3block2',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (2)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block3',                                  
                                  data: {
                                    title: '3block3',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (3)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block4',                                  
                                  data: {
                                    title: '3block4',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (4)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block5',                                  
                                  data: {
                                    title: '3block5',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (5)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block6',                                  
                                  data: {
                                    title: '3block6',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (6)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block7',                                  
                                  data: {
                                    title: '3block7',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (7)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: '3block8',                                  
                                  data: {
                                    title: '3block8',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsLayoutThreeColumns  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(86)} (8)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender',                                  
                                  data: {
                                    title: 'sender',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender2',                                  
                                  data: {
                                    title: 'sender2',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)} (2)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender3',                                  
                                  data: {
                                    title: 'sender3',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)} (3)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender4',                                  
                                  data: {
                                    title: 'sender4',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)} (4)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender5',                                  
                                  data: {
                                    title: 'sender5',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)} (5)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'sender6',                                  
                                  data: {
                                    title: 'sender6',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaWpforms  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(116)} (6)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'blockimg',                                  
                                  data: {
                                    title: 'blockimg',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <BsBlockquoteRight  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(118)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'numbers',                                  
                                  data: {
                                    title: 'numbers',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <TbNumbers  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(119)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'facts',                                  
                                  data: {
                                    title: 'facts',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <TbNumbers  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(120)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'pdf',                                  
                                  data: {
                                    title: 'pdf',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaRegFilePdf  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(145)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'adress',                                  
                                  data: {
                                    title: 'adress',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <GrMapLocation  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(146)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'documents',                                  
                                  data: {
                                    title: 'documents',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <IoDocumentsOutline  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(147)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'documents2',                                  
                                  data: {
                                    title: 'documents2',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <IoDocumentsOutline  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(147)} (2)
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'tarifs',                                  
                                  data: {
                                    title: 'tarifs',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaList  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(149)} 
                            </button>     
                            <button onClick={() => {    
                                const newData = {
                                  name: 'faq',                                  
                                  data: {
                                    title: 'faq',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaQuestion  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(150)}
                            </button>          
                            <button onClick={() => {    
                                const newData = {
                                  name: 'faq2',                                  
                                  data: {
                                    title: 'faq2',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaQuestion  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(150)} (2)
                            </button>               
                            <button onClick={() => {    
                                const newData = {
                                  name: 'faq3',                                  
                                  data: {
                                    title: 'faq3',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaQuestion  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(150)} (3)
                            </button>   
                            <button onClick={() => {    
                                const newData = {
                                  name: 'faq4',                                  
                                  data: {
                                    title: 'faq4',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }} className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <FaQuestion  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(150)} (4)
                            </button>    
                            <button onClick={() => {    
                                const newData = {
                                  name: 'deposit_cal',                                  
                                  data: {
                                    title: 'deposit_cal',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <MdCurrencyExchange  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(128)}
                            </button>
                            <button onClick={() => {    
                                const newData = {
                                  name: 'credit_cal',                                  
                                  data: {
                                    title: 'credit_cal',
                                  },
                                } 
                                if(langP === 'tg'){
                                  setcontentsTg([...contentsTg, newData]);
                                }
                                if(langP === 'ru'){
                                  setcontentsRu([...contentsRu, newData]);
                                }
                                if(langP === 'en'){
                                  setcontentsEn([...contentsEn, newData]);
                                }
                                setOpen(false)
                              }}
                              className='flex-col p-3 border rounded-lg items-center justify-center text-center hover:bg-gray-100 text-gray-400 hover:text-gray-500'>
                              <MdCurrencyExchange  className='h-10 mb-2 w-full items-center'/>
                              {GlobalText(129)}
                            </button>
                            
                            
                            
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
            </Transition.Root>
        </div>
        {<div className='flex mx-auto justify-between mt-8'>
          {sendloading ? <button disabled className="uppercase bg-gray-200 text-gray-500 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
          <div className="border-gray-300 h-5 w-5 animate-spin rounded-full border-4 border-t-gray-500 mr-3" /> {GlobalText(27)}
          </button> : <button onClick={(e) => Save(e)}          
          className="uppercase text-white bg-gray-500 hover:bg-gray-600 brt-text-medium rounded-lg text-sm py-3 px-4 text-center inline-flex items-center w-full justify-center sm:w-auto">
            {GlobalText(27)}
          </button>}
        </div> }
      </div>}
    </div>)
  }

  

  export default EditePage