import React, {Fragment, useEffect, useState, useRef } from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import GlobalText from './globalText'
import { IoMdCheckmark } from "react-icons/io";
import {
    Link,
    useLocation
  } from "react-router-dom";
import GlobalData from './globalData'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function Header(props) {
  const IMGURL = GlobalData('imgUrl')
  const { siteHeader, menu } = props;
  const logoTj = IMGURL + siteHeader['logo_tg']
  const logoRu = IMGURL + siteHeader['logo_ru']
  const logoEn = IMGURL + siteHeader['logo_en']

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation();
  const isCurrentlyEn = location.pathname.startsWith('/en');
  const isCurrentlyRu = location.pathname.startsWith('/ru');
  const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : 'tg' 
  const currentPathname = isCurrentlyEn || isCurrentlyRu
    ? location.pathname.substring(4) 
    : location.pathname.substring(1); 
  const parentPage = currentPathname.split('/')[0]
  const [isPage, setIsPage] = useState(false) 
  const [thisPage, setThisPage] = useState('/')
  const [thisPageParent, setThisPageParent] = useState(null) 
  const [menuChildren, setMenuchildren] = useState({});
  const { hash } = useLocation();
  const blockRef = useRef(null);
  

  useEffect(() => {
    if (location.hash === '#start' && blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location]);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0); 
    }
  }, [hash]);
  

  if(siteHeader){
    if(language === 'tg'){
      document.title = siteHeader.site_name_tg
    }
    if(language === 'ru'){
      document.title = siteHeader.site_name_ru
    }
    if(language === 'en'){
      document.title = siteHeader.site_name_en
    }
  }  

  useEffect(() => {    
    if(menu){
      setIsPage(false)
      setThisPage('/')
      setThisPageParent(null)
      menu.forEach((menuItem) => {
        const link = menuItem.link.substring(1)
        if(parentPage ===  link){
          setIsPage(true)
        }
        if(menuItem.children){
          menuChildren[menuItem.link] = menuItem.children;          
          menuChildren[menuItem.link].forEach((sub) => {
            const link2 = sub.link.substring(1)         
            if(currentPathname ===  link2 || currentPathname.startsWith(link2)){
              setThisPage(currentPathname)
              setThisPageParent(menuItem.link.substring(1))
            }
            
          })
        }      
      });
    }
  })
  return (
    <header className="bg-white px-6" id='start' ref={blockRef}>
      <nav className="mx-auto flex max-w-6xl items-center justify-between py-5 border-b border-gray-200 " aria-label="Global">
        <div className="flex mr-10">
          <Link to={language === 'tg' ? '/' : language} className="-m-1.5 p-1.5">
            <span className="sr-only">{GlobalText(5, language)}</span>
            <img className="h-8 w-auto" src={language === 'en' ? logoEn : language === 'ru' ? logoRu : logoTj} alt="BRT logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Меню</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <ul className="grid grid-flow-col gap-4 hidden lg:flex lg:gap-x-8 items-start">  
              {menu && menu.map((menuItem) => (
                <li className="relative">
                    <Link to={language === 'tg' ? menuItem.link : language + menuItem.link}>
                        <span className="cursor-pointer">{language === 'tg' ? menuItem.title_tg : language === 'ru' ? menuItem.title_ru : menuItem.title_en }</span>
                    </Link>
                {('/' + parentPage) === menuItem.link ? <hr className="absolute top-[49px] z-10 h-1 w-full brt-bg-blue"/> : (menuItem.link === '/' && !isPage) ? <hr className="absolute top-[49px] z-10 h-1 w-full brt-bg-blue"/> : <span /> }
                </li>
              ))}     
        </ul>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
            <div className="">
            <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-600 brt-text-regular hover:bg-gray-50"        
      >
        {GlobalText(0, language)}
        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-500 " aria-hidden="true" />
      </Menu.Button>
      <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
                <Menu.Item >
                {({ active }) => (
                    <Link to={currentPathname.length > 0 ? '/'+currentPathname : '/'}                                                   
                    className={language === "tg"
                    ? classNames(
                         'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                        )
                        : classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                          'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                      )
                      }
                    >
                    Тоҷикӣ
                    {language === "tg" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                    </Link>
                )}
                </Menu.Item>
                <Menu.Item  >
                {({ active }) => (
                    <Link to={currentPathname.length > 0 ? '/ru/'+currentPathname : '/ru'}                                                     
                    className={language === "ru"
                    ? classNames(
                         'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                        )
                        : classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                          'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                      )
                      }
                    >
                    Русский
                    {language === "ru" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                    </Link>
                )}
                </Menu.Item>
                <Menu.Item  >
                {({ active }) => (
                    <Link to={currentPathname.length > 0 ? '/en/'+currentPathname : '/en'}  
                    className={language === "en"
                    ? classNames(
                         'bg-sky-50 text-gray-600 block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                        )
                        : classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                          'block px-4 py-2 text-sm inline-flex w-full gap-x-5 brt-text-medium justify-between'
                      )
                      }
                    >
                    English 
                    {language === "en" && <IoMdCheckmark  className="-mr-1 h-4 w-5 brt-color-blue" aria-hidden="true" />}
                    </Link>
                )}
                </Menu.Item>
            </div>
            </Menu.Items>
        </Transition>  
        </Menu>
            </div>            
        </div>
      </nav>
      <nav className='mx-auto flex max-w-6xl'>
          <div className="brt-text-regular pt-3 text-gray-500">
              <ul className="hide-scrollbar container grid grid-flow-col justify-start gap-4 overflow-x-auto whitespace-nowrap pb-4.2 text-darkGrey md:overflow-visible md:mt-4.2">
                  {menuChildren[('/' + parentPage)] ? 
                    menuChildren[('/' + parentPage)].map((menuItem) => (
                      <li className="relative">
                          <Link to={language === 'tg' ? menuItem.link : language + menuItem.link}>
                              <span className={classNames(
                                menuItem.link.substring(1) === currentPathname ? 'text-blue-500' : '',
                                ' cursor-pointer hover:brt-color-2'
                              )}>{language === 'tg' ? menuItem.title_tg : language === 'ru' ? menuItem.title_ru : menuItem.title_en }</span>
                          </Link>
                      </li>
                    )) : menuChildren[('/' + thisPageParent)] ? menuChildren[('/' + thisPageParent)].map((menuItem) => (
                      <li className="relative">
                          <Link to={language === 'tg' ? menuItem.link : language + menuItem.link}>
                              <span 
                              className={classNames(
                                (menuItem.link.substring(1) === currentPathname || currentPathname.startsWith(menuItem.link.substring(1)))? 'text-blue-500' : '',
                                ' cursor-pointer hover:brt-color-2'
                              )}>
                                {language === 'tg' ? menuItem.title_tg : language === 'ru' ? menuItem.title_ru : menuItem.title_en }</span>
                          </Link>
                      </li>
                    )) : <span></span>
                  }
              </ul>
          </div>
      </nav>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-5 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={language === 'tg' ? '/' : language}  className="-m-1.5 p-1.5">
                <span className="sr-only">{GlobalText(5, language)}</span>
                <img className="h-8 w-auto" src={language === 'en' ? logoEn : language === 'ru' ? logoRu : logoTj} alt="BRT logo" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">{GlobalText(6, language)}</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10 ">
              <div className="space-y-2 py-6 ">
                {menu && menu.map((menuItem) => (
                  <Link to={menuItem.link} onClick={() => setMobileMenuOpen(false)}
                  className={('/' + parentPage) === menuItem.link 
                    ? "block rounded-lg px-3 py-2 leading-7 brt-bg-blue brt-color-white inline-flex w-full gap-x-5 brt-text-medium justify-between items-center"
                    : "block rounded-lg px-3 py-2 leading-7 text-gray-900 bg-gray-100 inline-flex w-full gap-x-5 brt-text-medium justify-between items-center hover:bg-blue-100"}
                >
                  {language === 'en' ? menuItem.title_en : language === 'ru' ? menuItem.title_ru :menuItem.title_tg }
                  <ChevronRightIcon className={('/' + parentPage) === menuItem.link  ? "-mr-1 h-5 w-5 brt-color-white" : "-mr-1 h-5 w-5 text-gray-900"} aria-hidden="true" />
                </Link>
                ))} 
              </div>
              <div className="pt-5">
              {GlobalText(7, language)}: 
               <div className='bg-blue-50 p-2 rounded-lg mt-1.5 '>
               <nav className="flex space-x-3 ">
               <Link to={currentPathname.length > 0 ? '/'+currentPathname : '/'}  
                    className = {language === 'tg' 
                        ? "px-4 py-2 text-slate-700 rounded-lg brt-bg-blue brt-text-regular brt-color-white w-full" 
                        : " px-4 py-2 text-slate-700 rounded-lg hover:bg-blue-100 hover:text-slate-900 bg-white w-full" }>
                    <center>Тоҷикӣ</center>
                </Link>
                <Link to={currentPathname.length > 0 ? '/ru/'+currentPathname : '/ru'}  
                    className = {language === 'ru' 
                        ? "px-4 py-2 text-slate-700 rounded-lg brt-bg-blue brt-text-regular brt-color-white w-full" 
                        : " px-4 py-2 text-slate-700 rounded-lg hover:bg-blue-100 hover:text-slate-900 bg-white w-full" }>
                    <center>Русский</center>
                </Link>
                <Link to={currentPathname.length > 0 ? '/en/'+currentPathname : '/en'}  
                    className = {language === 'en' 
                        ? "px-4 py-2 text-slate-700 rounded-lg brt-bg-blue brt-text-regular brt-color-white w-full" 
                        : " px-4 py-2 text-slate-700 rounded-lg hover:bg-blue-100 hover:text-slate-900 bg-white w-full" }>
                    <center>English</center>
                </Link>
                </nav>
               </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default Header